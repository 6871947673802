// src/App.js
import React, { useState, useEffect} from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import { useMediaQuery } from "react-responsive";
import List from "./List";
import Filters from "./Filters";
import { getQueryParams, getInitialFilter, fetchFiltered } from "./utils/Common.jsx"
import { defaultValues} from "./utils/Values.jsx"
import "./Search.css";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const App = () => {
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const [articles, setArticles] = useState([]);

    const query = useQuery();
    const navigate = useNavigate()
    const [filters, setFilters] = useState({
        rooms: getInitialFilter(query, 'rooms', defaultValues.rooms),
        minPriceUsd: getInitialFilter(query, 'minPriceUsd', defaultValues.minPriceUsd),
        maxPriceUsd: getInitialFilter(query, 'maxPriceUsd', defaultValues.maxPriceUsd),
        isOwner: getInitialFilter(query, 'isOwner', defaultValues.isOwner),
        city: getInitialFilter(query, 'city', defaultValues.city)
    });

    const updateFilter = (field, value) => {
        const updatedFilters = {
            ...filters,
            [field]: value
        };
        setFilters(updatedFilters);

        const params = getQueryParams(updatedFilters);
        navigate({
            pathname: window.location.pathname,
            search: params.toString(),
        }, { replace: true });
    };
    

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchFiltered(filters);
            if(response.data) {
                setArticles(response.data.articles.items);
            }        };
        fetchData();
    }, [filters]);

    return (
        <div className="search-container">
            <Filters filters={filters} updateFilter={updateFilter} />
            <div className="app">
                <div className={`content-container ${isMobile ? "" : "desktop"}`}>
                    <List
                            articles={articles}
                    />
                </div>
            </div>
        </div>
    );
};

export default App;
