export const rooms = ['1', '2', '3', '4+', 'ком.'];


export const cities = [
    'Минск',
    'Брест',
    "Витебск",
    "Гомель",
    "Гродно",
    "Могилёв",
    "Бобруйск",
    "Борисов",
    "Барановичи",
    "Орша"
]

export const defaultValues = {
    city: 'Минск',
    rooms: [],
    minPriceUsd: '',
    maxPriceUsd: '',
    isOwner: false
};